import React from "react"

import BigTitle from "./BigTitle"

const CallToAction = ({ bigTitle, children }) => {
  return (
    <section className="call-to-action background-pink u-center-text">
      <div className="u-margin-top-big u-margin-bottom-big u-padding">
        <BigTitle bigTitle={bigTitle} />
        <div className="row">
          <div className="col-1-small">{children}</div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction
