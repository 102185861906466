import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

const query = graphql`
  {
    heroBackgroundImage: allPrismicImages(
      filter: { data: { image: { alt: { eq: "Vermont web design" } } } }
    ) {
      nodes {
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(query)
  const {
    heroBackgroundImage: { nodes: heroBackgroundImage },
  } = data
  // const heroBackgroundTitle = heroBackgroundImage[0].data.image.alt
  // const fluidOne = heroBackgroundImage[0].data.image.fluid

  return (
    <section id="hero" className="hero u-margin-bottom-big">
      <HeroBackground className="bcg">
        <section className="hero__header">
          <div className="row">
            <div className="col-1-of-1">
              <article className="hero__info u-margin-top">
                <h1 className="heading-main u-margin-bottom-medium">
                  I create beautiful websites that work
                </h1>
                <p className="heading-primary--main heading-primary--main--black">
                  Maximize your business potential with performant websites.
                  Build with the latest, innovative technologies to enhance your
                  online presence and drive your business to success.
                </p>

                <div className="call-to-action-link u-margin-top">
                  <p className="heading-primary--main u-margin-bottom-small">
                    <HiOutlineArrowNarrowRight />
                    <Link to="/contact">Make it happen</Link>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </section>
      </HeroBackground>
    </section>
  )
}

const HeroBackground = styled.header`
  background: rgba(82, 183, 136, 0.1);
`

export default Hero
