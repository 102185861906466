import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { InnerHero, Layout } from "../components"
import Seo from "../components/seo"

const query = graphql`
  {
    imageOne: allPrismicImages(
      filter: { data: { image: { alt: { eq: "404 page UFO" } } } }
    ) {
      nodes {
        uid
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

const Error = ({ page }) => {
  const {
    imageOne: { nodes: imageOne },
  } = useStaticQuery(query)
  const { imageOneAlt } = imageOne[0].data.image

  const fluidOne = imageOne[0].data.image.fluid

  return (
    <Layout>
      <Seo
        title="error - Vermont Web Development"
        description="Page Not Found"
      />
      <main>
        <section id="error">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <div className="row">
                    <div className="col-2-of-2">
                      <div className="u-wrap-vertical-center">
                        <article>
                          <h1 className="heading__main-title heading__main-title--black">
                            Page Not Found
                          </h1>

                          <Link
                            to="/"
                            className="btn btn--primary u-margin-top btn--animated"
                          >
                            home
                          </Link>
                        </article>
                      </div>

                      <figure>
                        <Image
                          fluid={fluidOne}
                          fadeIn={false}
                          loading="eager"
                          className="error___fluid-image fluid-image"
                          alt={imageOneAlt}
                        />
                      </figure>
                    </div>
                  </div>
                </InnerHero>
              </>
            )}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Error
