import React from "react"
import Image from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"

import CallToAction from "./CallToAction"
import InnerHero from "./InnerHero"

const query = graphql`
  {
    imageOne: allPrismicImages(
      filter: { data: { image: { alt: { eq: "company mission" } } } }
    ) {
      nodes {
        uid
        data {
          image {
            alt
            fixed(width: 300, height: 300) {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
        }
      }
    }

    imageThree: allPrismicImages(
      filter: { data: { image: { alt: { eq: "Krittiya Clark" } } } }
    ) {
      nodes {
        uid
        data {
          image {
            alt
            fixed(width: 300, height: 300) {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
        }
      }
    }

    ourValues: allPrismicOurValues {
      nodes {
        data {
          info {
            text
          }
          name {
            text
          }
        }
        id
      }
    }
  }
`

const AboutUs = ({ page }) => {
  const {
    imageOne: { nodes: imageOne },
    imageThree: { nodes: imageThree },
    ourValues: { nodes: ourValues },
  } = useStaticQuery(query)
  const imageOneTitle = imageOne[0].data.image.alt
  const imageThreeTitle = imageThree[0].data.image.alt

  const fixOne = imageOne[0].data.image.fixed
  const fixThree = imageThree[0].data.image.fixed

  console.log(imageOneTitle)
  return (
    <>
      <section id="about-us">
        <div className="section u-padding-bottom">
          {!page && (
            <>
              <InnerHero>
                <h1 className="heading__main-title heading__main-title--black">
                  Hi there!
                </h1>
                <h3 className="heading__main-title--sub u-no-effect">
                  As a web developer freelancer, I'll build your website from
                  scratch or redesign it for improved functionality and speed.
                </h3>
              </InnerHero>
            </>
          )}
        </div>

        <section id="about-us__marketing" className="section u-padding">
          <div className="u-column-wrap">
            <div className="row">
              <div className="col-2-of-2">
                <article className="about-us__marketing__article">
                  <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                    Mission
                  </h2>
                  <p className="paragraph">
                    I'm passionate about Jamstack. When it comes to web design,
                    my mission is to provide clients with high-performing, safe,
                    and easy-to-maintain websites that match their budget.
                  </p>
                </article>

                <article>
                  <figure>
                    <Image
                      fixed={fixOne}
                      fadeIn={false}
                      loading="eager"
                      alt={imageOneTitle}
                    />
                  </figure>
                </article>
              </div>
            </div>
          </div>

          <div className="u-column-wrap u-left-text">
            <div className="row">
              <div className="col-2-of-2">
                <figure>
                  <Image
                    fixed={fixThree}
                    fadeIn={false}
                    loading="eager"
                    alt={imageThreeTitle}
                  />
                </figure>

                <article className="about-us__marketing__article">
                  <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium u-margin-bottom-small u-left-text">
                    Krittiya Clark
                  </h2>
                  <p className="paragraph u-left-text">
                    I'm a web developer working with teams around the world to
                    help create high-performance websites.{" "}
                  </p>
                  <p className="paragraph u-left-text">
                    With an eye for the future, I started teaching myself coding
                    7 years ago, and have always taken a deep interest in making
                    websites the best they can be for everyone using them.
                  </p>
                  <p className="paragraph u-left-text">
                    With my background in art, I'm able to create beautiful
                    websites that are high-performing and easy for your team to
                    edit while also delivering the best experience for your
                    users.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>

        <section
          id="about-us__our-values"
          className="about-us__our-values background u-padding"
        >
          <div className="row">
            <div className="col-2-small-of-2 u-margin-top-huge u-no-margin-bottom">
              <article>
                <h3 className="heading__main-title--sub u-no-effect">
                  My values
                </h3>
              </article>

              <div className="col-2-of-2">
                {ourValues &&
                  ourValues.map(ourValue => {
                    const { name, info } = ourValue.data
                    const { id } = ourValue

                    return (
                      <article key={id}>
                        <div className="u-margin-bottom-medium">
                          <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                            {name.text}
                          </h2>
                          <p className="paragraph">{info.text}</p>
                        </div>
                      </article>
                    )
                  })}
              </div>
            </div>
          </div>
        </section>
      </section>

      <CallToAction bigTitle="Vermont Web Development - Make Your Business Grow">
        <h2 className="heading-primary heading-primary--main u-margin-top-small u-no-margin-bottom">
          Want to make an impression? I create website designs that are
          professional, clean and unique. Click here for more information about
          web design packages.
        </h2>
        <button aria-label="button">
          <Link to="/contact" className="btn btn--primary u-margin-top">
            Let's talk!
          </Link>
        </button>
        <p id="text">free consultation</p>
      </CallToAction>
    </>
  )
}

export default AboutUs
