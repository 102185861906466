import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import Image from "gatsby-image"

import Title from "./Title"

const Portfolio = ({ portfolios, page }) => {
  return (
    <Wrapper className="section u-margin-bottom-medium">
      {!page && (
        <div className="u-header-wrap u-margin-bottom-big">
          <div className="row">
            <div className="col-1-of-1 u-margin-top-big">
              <Title title="Portfolio" />
              <h2 className="heading-primary heading-primary--main">
                Web design & Web development
              </h2>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-3-of-3">
          {portfolios.map(item => {
            const { id, alt } = item
            const { slug, title, technologies } = item.data
            const fluid = item.data.image.fluid

            return (
              <Link to={`/portfolio/${slug.text}`}>
                <article key={id}>
                  <div className="portfolio--container">
                    <figure>
                      <Image
                        fluid={fluid}
                        alt={alt}
                        fadeIn={false}
                        loading="eager"
                      />
                    </figure>
                    <div className="portfolio--info">
                      <h3 className="heading-tertiary u-margin-bottom-medium">
                        {title.text}
                      </h3>
                      {technologies &&
                        technologies.map((item, id) => {
                          return (
                            <ul
                              className="portfolio--info-list paragraph u-tags"
                              key={id}
                            >
                              <li className="u-tag is-light">
                                {item.technology.text}
                              </li>
                            </ul>
                          )
                        })}
                    </div>
                  </div>
                </article>
              </Link>
            )
          })}
        </div>

        {!page && (
          <div className="call-to-action-link u-center-text">
            <p className="paragraph u-margin-top">
              <HiOutlineArrowNarrowRight />
              <Link to="/portfolio">View all projects</Link>
            </p>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .portfolio--info {
    color: #000000;
    text-transform: uppercase;
    padding: 2rem 0;
    text-align: center;

    .portfolio--info-list {
      display: inline-block;
      padding-right: 2rem;
    }

    .heading-secondary--small {
      color: #000000;
      font-weight: 900;
      text-transform: capitalize;
    }
  }

  a {
    text-align: center;
  }
`

export default Portfolio
